

















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DashEntrevistaModule from "@/store/modules/Dashboards/Empresarial/DashEntrevista-module";
import { UtilsPaciente } from "@/utils/utils-paciente";
import patologiaModule from "@/store/modules/patologia-module";
import { TipoPatologia } from "@/utils/TipoPatologia";
@Component({
  components: {
    ColumnsChart: () => import("@/components/Charts/ColumnsChart.vue"),
    MaterialCard: () => import("@/components/Cards/MaterialCard.vue")
  }
})
export default class DashComparacionMediciones extends Vue {
  public objetivosSeleccionadosItems: any[] = [];
  public async created() {
    DashEntrevistaModule.getHombreMujer(this.objetivosSeleccionadosItems);
    await patologiaModule.getpatologiasFromCahe(
      TipoPatologia.ObjetivoTratamiento
    );
    this.objetivosSeleccionadosItems = this.objetivos;
  }
  public get source() {
    return DashEntrevistaModule.EvolucionPacientes;
  }
  public get objetivos() {
    let objetivos: any[] = [];
    for (
      let i = 0;
      i < patologiaModule.patologiasObjetivoTratamiento.length;
      i++
    ) {
      const element = patologiaModule.patologiasObjetivoTratamiento[i];
      objetivos.push({
        text: element.nombre_patologia,
        value: element.nombre_patologia
      });
    }
    return objetivos;
  }
  @Watch("objetivosSeleccionadosItems")
  public changefilter() {
    DashEntrevistaModule.getHombreMujer(this.objetivosSeleccionadosItems);
  }
  public getImagen(sexo: string) {
    return UtilsPaciente.getrutaImagenFromString(sexo);
  }
}
